import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

// import {graphql, useStaticQuery} from "gatsby";
import {HeroBlock} from "../components/heroBlock/heroBlock";
import {ServicesBlock} from "../components/servicesBlock/servicesBlock";

import {CasesBlock} from "../components/casesBlock/casesBlock";
import {ContactBlock} from "../components/contactBlock/contactBlock";
import CompanyBlock from "../components/companyBlock/companyBlock";


const IndexPage = () => {
  // const { site } = useStaticQuery(
  //   graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           version
  //         }
  //       }
  //     }
  //   `
  // )

  return (
    <Layout>
      <Seo
        lang={'en'}
        title="Golang development company | Your development assistant in Armenia"
        description={"We are professional Golang development experts with a decade of experience. Our team helps startups and large companies achieve their tech goals by providing comprehensive development and consulting services. Improve your business performance with the help of our Golang developers!"}
      />

      <HeroBlock />
      <CompanyBlock />
      <ServicesBlock />

      <CasesBlock />
      <ContactBlock />

    </Layout>
  )

}

export default IndexPage


