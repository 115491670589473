// extracted by mini-css-extract-plugin
export var bg = "heroBlock-module--bg--WE8pC";
export var button = "heroBlock-module--button--nU5d0";
export var content = "heroBlock-module--content--P7UEN";
export var desc = "heroBlock-module--desc--MKLY+";
export var heroBlock = "heroBlock-module--heroBlock--BzfVH";
export var heroBlockWrap = "heroBlock-module--heroBlockWrap---r+Bs";
export var image1 = "heroBlock-module--image1--N5o3M";
export var image2 = "heroBlock-module--image2--0Sye+";
export var image3 = "heroBlock-module--image3--1W0y5";
export var title = "heroBlock-module--title--t99LS";