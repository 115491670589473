import * as React from "react";
import * as styles from "./heroBlock.module.scss"
import {StaticImage} from "gatsby-plugin-image";

const HeroBlock = ({title, description, bg})=>{
  return (
    <div className={styles.heroBlock}>

      <div className={styles.heroBlockWrap}>
        <div className={styles.bg}>
          <div className={styles.image3}>
            <StaticImage src={'../../images/cover_image-3.svg'} alt={''} placeholder={"none"}/>
          </div>
          <div className={styles.image1}>
            <StaticImage src={'../../images/cover_image-1.png'} alt={''} placeholder={"none"} />
          </div>
          <div className={styles.image2}>
            <StaticImage src={'../../images/cover_image-2.png'} alt={''} placeholder={"none"} />
          </div>
        </div>

        <div className={styles.content}>
          <h1 className={styles.title}>GoLang Development Company</h1>
          <div className={styles.desc}>
            Maximize your business performance with robust Golang solutions
          </div>
          <a className={styles.button} href={'#contacts'}>Let’s work together</a>
        </div>

      </div>


    </div>
  )
}

export {HeroBlock}