import * as React from "react";
import * as styles from "./casesBlock.module.scss"
import {StaticImage} from "gatsby-plugin-image";

const CasesBlock = ()=>{
  return (
    <div className={styles.casesBlock} id={'expertise'}>
      <div className={styles.casesBlockIn}>
        <div className={styles.casesBlockWrap}>
          <div className={styles.title}>Our cases </div>
        </div>
      </div>
      <div className={styles.casesWrap}>
        <div className={styles.caseCard}>
          <div className={styles.caseBg}>
            <StaticImage
              src={'../../images/case-cryptopay.jpg'}
              alt={''}
              layout="fullWidth"
              formats={["auto", "webp"]}
            />
          </div>
          <div className={styles.caseWrap}>
            <div className={styles.caseName}>Cryptopay</div>
            <div className={styles.caseDesc}>Custom B2B platform for crypto payment provider Cryptopay</div>
          </div>
        </div>
        <div className={styles.caseCard}>
          <div className={styles.caseBg}>
            <StaticImage
              src={'../../images/case-jiseki.jpg'}
              alt={''}
              layout="fullWidth"
              formats={["auto", "webp"]}
            />
          </div>
          <div className={styles.caseWrap}>
            <div className={styles.caseName}>Jiseki Health</div>
            <div className={styles.caseDesc}>Building a HIPAA-compliant rules engine for medtech startup</div>
          </div>
        </div>
        <div className={styles.caseCard}>
          <div className={styles.caseBg}>
            <StaticImage
              src={'../../images/case-vexor.jpg'}
              alt={''}
              layout="fullWidth"
              formats={["auto", "webp"]}
            />
          </div>
          <div className={styles.caseWrap}>
            <div className={styles.caseName}>Vexor</div>
            <div className={styles.caseDesc}>Distributed cloud web-service for building and testing software</div>
          </div>
        </div>
        <div className={styles.caseCard}>
          <div className={styles.caseBg}>
            <StaticImage
              src={'../../images/case-kfc.jpg'}
              alt={''}
              layout="fullWidth"
              formats={["auto", "webp"]}
            />
          </div>
          <div className={styles.caseWrap}>
            <div className={styles.caseName}>KFC</div>
            <div className={styles.caseDesc}>Creating a custom CRM to KFC's customer-defined service</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {CasesBlock}