import * as React from "react";
import * as styles from './servicesBlock.module.scss';

const ServicesBlock = ()=>{
  return (
    <div className={styles.servicesBlock} id={'services'}>
      <div className={styles.servicesBlockWrap}>
        <div className={styles.titleWrap}>
          <div className={styles.title}>Custom GoLang development services</div>
        </div>
        <div className={styles.cardsWrap}>
          <div className={styles.card}>
            <div className={styles.caption}>GoLang web app development</div>
            <div className={'servicesBlockCardText'}>
              When you partner with our Golang web development company, our experts will deliver a scalable, high-performing application that meets your business needs.
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.caption}>GoLang mob app development </div>
            <div className={'servicesBlockCardText'}>
              Our Go development team can help you get the robust, scalable mobile app that you need, with the responsive, user-friendly UI that your customers want.
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.caption}>Go cloud app development </div>
            <div className={'servicesBlockCardText'}>
              Take advantage of Go’s cloud capabilities and powerful toolset and hire Golang developers to develop hybrid or multi-cloud solutions that work for you.
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.caption}>Custom GoLang development </div>
            <div className={'servicesBlockCardText'}>
              We love a challenge, and our Golang development company is dedicated to finding the right solutions to build the custom web or mobile app that you’ve envisioned.
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.caption}>Сross-platform development </div>
            <div className={'servicesBlockCardText'}>
              Streamline development, get faster time-to-market, and enjoy flawless functionality across all platforms with our GoLang cross-platform development expertise.
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.caption}>GoLang support & maintenance </div>
            <div className={'servicesBlockCardText'}>
              We don’t disappear when the product is delivered. Our team can provide continuous maintenance and support of your GoLang app, so your business can keep running smoothly.
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export {ServicesBlock}