import * as React from "react";
import * as styles from "./contactBlock.module.scss"

const ContactBlock = ()=>{
  return (
    <div className={styles.contactBlock} id={'contacts'}>
      <div className={styles.contactBlockWrap}>
        <div className={styles.title}>Contact us</div>
        <div className={styles.content}>
          <div className={styles.col}>
            <div className={styles.text}>
              4/3 Pirumyanner, 4th floor, suite 12, Davtashen 0054, Yerevan, Yerevan, Armenia
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.text}>
              phone: <a href={'tel:+37499557821'}>+374 99 557821</a>
              <br/>
              <a href={'mailto:mail@devassistant.pro'}>mail@devassistant.pro</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ContactBlock}