// extracted by mini-css-extract-plugin
export var bottom = "companyBlock-module--bottom--SCrNt";
export var bottomWrap = "companyBlock-module--bottomWrap--Of5WF";
export var caption = "companyBlock-module--caption--QaMfe";
export var card = "companyBlock-module--card--wT-o1";
export var cards = "companyBlock-module--cards--Liv86";
export var desc = "companyBlock-module--desc--4I2o9";
export var icon = "companyBlock-module--icon--kABRG";
export var title = "companyBlock-module--title--2HwUK";
export var top = "companyBlock-module--top--0PAKk";
export var topWrap = "companyBlock-module--topWrap--S-VDU";